<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 搜索与添加区 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary"
                     @click="showAddClientDialog">新增客户端版本</el-button>
        </el-col>
      </el-row>
      <el-table :data="clientList"
                style="width: 100%"
                stripe
                border>
        <el-table-column prop="id"
                         label="id"
                         width="100"> </el-table-column>
        <el-table-column prop="applicationId"
                         label="bundle Id"
                         width="150">
        </el-table-column>
        <el-table-column prop="appName"
                         label="应用名称"> </el-table-column>
        <el-table-column prop="versionCode"
                         label="版本号"> </el-table-column>
        <el-table-column prop="versionName"
                         label="版本名称"> </el-table-column>
        <!-- <el-table-column prop="appSize" label="文件大小" width="80">
          <template slot-scope="scope">
            <div>{{ scope.row.appSize / 1024 / 1024 }}MB</div>
          </template>
        </el-table-column> -->
        <el-table-column label="文件大小">
          <template slot-scope="scope">
            <div>{{ scope.row.newSize }}MB</div>
          </template>
        </el-table-column>

        <el-table-column prop="downloadUrl"
                         label="文件地址"
                         width="200">
        </el-table-column>
        <el-table-column prop="newUpdateFlag"
                         label="更新方式">
          <template slot-scope="scope">
            <div v-if="scope.row.updateFlag === 0">普通更新</div>
            <div v-else-if="scope.row.updateFlag == 1">
              强制更新
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateDescription"
                         label="更新描述">
        </el-table-column>
        <!-- 发布情况 -->
        <el-table-column label="发布情况">
          <template slot-scope="scope">
            <div v-if="scope.row.status === 0">未发布</div>
            <div v-else-if="scope.row.status == 1">
              已发布
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <!-- 发布按钮 -->
            <el-button
              type="success"
              size="mini"
              @click="putClient(scope.row.id)"
              v-if="scope.row.status === 0"
              >发布</el-button
            >
            <!-- 取消发布按钮 -->
            <el-button
              type="warning"
              size="mini"
              @click="cancelPutClient(scope.row.id)"
              v-else
              >取消发布</el-button
            >
            <!-- 修改按钮 -->

            <!-- <el-button
              type="primary"
              size="mini"
              @click="showEditDialog(scope.row)"
              >修改</el-button
            > -->
            <!-- 删除按钮 -->
            <el-button type="danger"
                       size="mini"
                       @click="deleteClient(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.pageNo"
                     :page-sizes="[15, 30, 50, 100]"
                     :page-size="queryInfo.limit"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加商户对话框 -->
    <el-dialog :title="title"
               :visible.sync="addClientDialogVisible"
               width="50%"
               @close="clearInfoDialog">
      <el-form ref="ClientFormRef"
               :rules="ClientFormRules"
               :model="ClientForm"
               label-width="100px">
        <el-form-item label="应用名称"
                      prop="appName"
                      v-if="isShowEdit">
          <el-input v-model="ClientForm.appName"
                    placeholder="请输入应用名称"></el-input>
        </el-form-item>
        <el-form-item label="应用版本号"
                      prop="versionCode"
                      v-if="isShowEdit">
          <el-input v-model="ClientForm.versionCode"
                    placeholder="请输入应用版本名称"></el-input>
        </el-form-item>
        <el-form-item label="应用版本名称"
                      prop="versionName"
                      v-if="isShowEdit">
          <el-input v-model="ClientForm.versionName"
                    placeholder="请输入应用版本名称"></el-input>
        </el-form-item>
        <el-form-item label="androId或ios bundle Id"
                      prop="applicationId"
                      v-if="isShowEdit">
          <el-input v-model="ClientForm.applicationId"
                    placeholder="请输入androId或ios bundle Id"></el-input>
        </el-form-item>
        <el-form-item label="文件大小"
                      prop="appSize"
                      v-if="isShowEdit">
          <el-input v-model="ClientForm.appSize"
                    placeholder="请输入文件大小"></el-input>
        </el-form-item>
        <el-form-item label="文件地址"
                      prop="downloadUrl">
          <!-- <el-input
            v-model="ClientForm.downloadUrl"
            placeholder="请输入文件地址"
          ></el-input> -->
          <!-- 上传文件 -->
          <el-upload class="upload-demo"
                     action="/api/v1/file/file"
                     accept=".apk"
                     :limit="1"
                     :data="this.bizType1"
                     :file-list="fileListUrl"
                     :on-success="handleChange"
                     ref="upload"
                     :show-file-list="true">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <!-- <el-upload class="upload-demo"
                     action="/api/v1/file/file"
                     accept=".apk"
                     :limit="1"
                     :data="this.bizType1"
                     :file-list="fileListUrl"
                     :on-change="handleChange"
                      ref="upload">
           <el-button size="small" type="primary">点击上传</el-button>
          </el-upload> -->
        </el-form-item>
        <el-form-item label="更新方式"
                      prop="updateFlag">
          <el-radio v-model="ClientForm.updateFlag"
                    label="0">普通更新</el-radio>
          <el-radio v-model="ClientForm.updateFlag"
                    label="1">强制更新</el-radio>
        </el-form-item>
        <el-form-item label="更新描述"
                      prop="updateDescription">
          <el-input v-model="ClientForm.updateDescription"
                    placeholder="请输入更新描述"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addClientDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submitClient">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1
      },
      total: 0,
      clientList: [],
      addClientDialogVisible: false,
      // 添加form校验规则
      ClientFormRules: {
        updateFlag: [
          { required: true, message: '请输入文件更新方式', trigger: 'blur' }
        ],
        downloadUrl: [
          { required: true, message: '请上传文件', trigger: 'blur' }
        ]
      },
      ClientForm: {
        // 文件地址
        downloadUrl: '',
        downloadUrl2: '',
        // 更新描述
        updateDescription: '',
        // 更新方式
        updateFlag: '',
        // 应用名称
        appName: '',
        // 应用版本号
        versionCode: '',
        // 应用版本名称
        versionName: '',
        // androId或ios bundle Id
        applicationId: '',
        // 文件大小
        appSize: ''
      },
      // 标题名称
      title: '',
      id: '',
      // 提交类型
      submitType: '',
      // form表单是否展示
      isShowEdit: '',
      bizType1: {
        bizType: 'clientStyle'
      },
      fileListUrl: [],
      fileListUrls: '',
      fileName: '',
      // 状态
      status: undefined
    }
  },
  created() {
    this.getClientList()
  },
  methods: {
    async getClientList() {
      const {
        data: res
      } = await this.$http.get(
        '/agriculturalAppClient/agriculturalAppClientList',
        { params: this.queryInfo }
      )
      // console.log(res)
      // this.clientList = res.data.data
      this.clientList = res.data.data.map((item) => ({
        ...item,
        newUpdateFlag: item.updateFlag === 0 ? '普通更新' : '强制更新',
        newSize: (item.appSize / 1024 / 1024).toFixed(1)
      }))
      // console.log(this.clientList)
      this.total = res.data.total
      // console.log(this.total)
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getClientList()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getClientList()
    },
    showAddClientDialog() {
      this.title = '添加客户端版本'
      this.submitType = 'add'
      this.isShowEdit = false
      // this.ClientForm.downloadUrl = response.data
      this.addClientDialogVisible = true
    },
    showEditDialog(row) {
      this.id = row.id
      // console.log(row)
      this.ClientForm = row
      this.ClientForm.downloadUrl2 = row.downloadUrl
      this.ClientForm.updateFlag = row.updateFlag.toString()
      // this.id = id
      this.title = '修改客户端版本'
      this.submitType = 'edit'
      this.isShowEdit = true
      this.addClientDialogVisible = true
    },
    // 提交对话框
    submitClient() {
      if (this.submitType === 'add') {
        this.ClientForm.downloadUrl = this.fileListUrls
        this.$refs.ClientFormRef.validate(async (valid) => {
          if (!valid) return
          // 可以发起网络请求
          const { data: res } = await this.$http.post(
            '/agriculturalAppClient/',
            this.ClientForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加客户端版本成功')
          this.addClientDialogVisible = false
          this.getClientList()
          this.$refs.upload.clearFiles()
        })
      } else {
        if (this.fileListUrls === '') {
          this.ClientForm.downloadUrl = this.ClientForm.downloadUrl2
        } else {
          this.ClientForm.downloadUrl = this.fileListUrls
        }
        // this.ClientForm.downloadUrl = this.fileListUrls
        // console.log(this.ClientForm.downloadUrl, 222333)
        this.$refs.ClientFormRef.validate(async (valid) => {
          if (!valid) return
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `/agriculturalAppClient/${this.id}`,
            this.ClientForm
          )
          if (res.code !== 0) {
            return this.$message.error('修改客户端版本失败')
          }
          this.$message.success('修改客户端版本成功')
          this.addClientDialogVisible = false
          this.getClientList()
          this.$refs.upload.clearFiles()
        })
      }
    },
    // 删除客户端版本
    async deleteClient(id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该客户端版本，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        `/agriculturalAppClient/${id}`
      )
      if (res.code !== 0) {
        return this.$message.error('删除客户端版本失败')
      }
      this.$message.success('删除客户端版本成功')
      this.getClientList()
    },
    // 发布客户端版本
    async putClient(id) {
      // 赋值状态
      this.status = '1'
      // 发布提醒
      const confirmResult = await this.$confirm(
        '此操作将发布该客户端版本，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消发布')
      }
      const { data: res } = await this.$http.put(
        `/agriculturalAppClient/publish/${id}`,
        this.status
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('发布成功')
      this.getClientList()
    },
    // 取消发布客户端版本
    async cancelPutClient(id) {
      // 赋值状态
      this.status = '0'
      // 发布提醒
      const confirmResult = await this.$confirm(
        '此操作将取消发布该客户端版本，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消操作')
      }
      const { data: res } = await this.$http.put(
        `/agriculturalAppClient/publish/${id}`,
        this.status
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('取消成功')
      this.getClientList()
    },
    // 关闭对话框清空信息
    clearInfoDialog() {
      this.ClientForm = {}
      this.fileListUrls = ''
      this.$refs.upload.clearFiles()
      this.$refs.ClientFormRef.resetFields()
    },
    async handleChange(response, file, fileList) {
      // this.fileListUrl = file
      this.fileListUrl.push({ name: response.data, url: response.data })
      this.fileListUrls = response.data
    }
  }
}
</script>

<style lang="less" scoped></style>
